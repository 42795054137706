<template>
    <div style="width: 100%">
        <div style="width: 100%;" v-for="(item,idex) in list" :key="item.id">
            <div style="" v-if="item.parentId == agentConst.Parnter.PATNER_GENERALAGENT_NOID"></div>
            <div class="agentline">
<!--                <div style="width: 4%;text-align: center;color: #c6c6c6">-->
<!--                    [{{item.id}}]-->
<!--                </div>-->
                <div style="width: 4%">
                    <span v-if="item.status == agentConst.ENABLE">[정상]</span>
                    <span style="color: red" v-if="item.status == agentConst.DISABLE">[블럭]</span>

                </div>

                <div style="width: 6%">
                   <span>회원[{{item.userCount}}]</span>
                </div>

                <div style="width: 6%">
                    블럭<span style="color: red">[{{item.userCountBlock}}]</span>
                </div>
                <div style="width: 7%">
                    <i class="fa fa-won"></i> <span style="color: #afafaf">{{item.cash|comma}} 원</span>
                </div>

                <div style="width:43%">
                    <span v-if="item.parentId != agentConst.Parnter.PATNER_GENERALAGENT_NOID">|</span>
                    <span v-for="index in item.level"
                          v-if="item.parentId != agentConst.Parnter.PATNER_GENERALAGENT_NOID">---</span>
                    <span style="color: #dd7f7f;font-size: 11px;padding: 0 5px">lv[{{item.level}}]</span>
                    <span class="badge badge-warning" style="color: #143771">{{item.nickname}}</span>

                    <span style="color: #9b9999">({{item.account}})</span>
                    <i class="fa fa-hand-o-right" style="padding: 0 5px"></i>


                    <span style="color: skyblue;font-size: 11px">손이익(<span style="color: #fff9ff">{{item.rechargeExchangeProfit}}</span>)%</span>,
                    <span style="color: orange;font-size: 11px">미니게임롤링(<span style="color: #fff9ff">{{item.leisureBetRollingProfit}}</span>)%</span>,
                    <span style="color: skyblue;font-size: 11px">카지노롤링(<span style="color: #fff9ff">{{item.casinoBetRollingProfit}}</span>)%</span>,
                    <span style="color: orange;font-size: 11px">슬롯롤링(<span style="color: #fff9ff">{{item.slotBetRollingProfit}}</span>)%</span>,
                    <span style="color: skyblue;font-size: 11px">스포츠롤링(<span style="color: #fff9ff">{{item.sportsBetRollingProfit}}</span>)%</span>


                </div>


                <div style="width: 30%;">
                    <el-button type="danger" size="mini" @click="addPartner(item.id,agentConst.MODE_EDIT)"><i
                            class="fa fa-pencil-square"></i> 총판수정
                    </el-button>
                    <el-button type="primary" size="mini" @click="editPartner(item.id,agentConst.MODE_SAVE)"><i
                            class="fa fa-plus-square"></i> 하위총판추가
                    </el-button>
                </div>
            </div>
            <div v-if="isshowprofit" style="background-color: #9e9e9e;padding: 5px 0">
                <span style="color:#d8f84a;">본인&회원입금:{{item.currentAgentRecharegeAmountTotal|comma}}</span> |
                <span style="color:#d8f84a;">본인&회원출금:-{{item.currentAgentExchangeAmountTotal|comma}}</span> |
                <span style="color:#0510e7;font-weight: bold">본인&회원손이익:{{item.ownUserProfit|comma}}</span>
            </div>
            <div v-if="isshowprofit" style="background-color: #c1c1c1;padding: 5px 0">
                <span style="color:#607600;">하위&회원입금:{{item.subAgentRecharegeAmountTotal|comma}}</span> |
                <span style="color:#607600;">하위&회원출금:-{{item.subAgentExchangeAmountTotal|comma}}</span> |
                <span style="color:#4c55f6;font-weight: bold">하위&회원손이익:{{item.subUserProfit|comma}}</span>
            </div>
            <div v-if="isshowprofit" style="background-color: #c1c1c1;padding: 5px 0">
                <span style="color: #5477f5" v-if="item.givebackdate">최근정산일:{{item.givebackdate|datef('MM-DD HH:mm')}} | </span>
                전체손이익<i class="fa fa-won"></i><span style="color: #5e0b0b">:{{(item.ownUserProfit + item.subUserProfit)|comma}}</span>
            </div>
            <agent-recursion-comp2 v-if="item.subAgentList" :list="item.subAgentList" :isshowprofit="isshowprofit"></agent-recursion-comp2>
        </div>
    </div>

</template>

<script>
    import Vue from "vue";
    import AgentEditToast from "./toast/AgentEditToast";
    import {agentMixin} from "../../common/agent/agentMixin";

    Vue.prototype.$agentEditToast = AgentEditToast

    export default {
        name: "AgentRecursionComp2",
        mixins: [agentMixin],
        props: {
            list: {
                type: Array,
                default() {
                    return []
                }
            },
            isshowprofit: {
                type:Boolean,
                default() {
                    return false
                }
            }

        },
        data() {
            return {
                agent: {},
                editParnterId: 0,
            }
        },
        methods: {
            editPartner(id, mode) {
                this.$agentEditToast({'agentId': id, 'mode': mode})
            },
            addPartner(parentId, mode) {
                this.$agentEditToast({'agentId': parentId, 'mode': mode})
            },
        }
    }
</script>

<style scoped>
    .agentline {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        height: 70px;
        width: 100%;
        background-color: #4a4a4a;
        color: #fff9ff;
        border-bottom: 1px solid #505050
    }
</style>